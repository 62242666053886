<template>
	<main>
		<div class="main">
			<h1 class="title">SERVICE ITEMS</h1>
		</div>
		<div class="all">
			<div class="center">
				<div class="title-box">
					<div class="title">服务内容</div>
				</div>
				<div class="list">
					<div class="wrap">
						<div class="item">
							<div class="tit">品牌设计</div>
							<div class="min-tit">BRAND DESIGN</div>
							<div class="line"></div>
							<div class="ll">
								<p>LOGO 设计</p>
								<p>网站设计</p>
								<p>Web App 设计</p>
								<p>交互动画设计</p>
								<p>3D 建模</p>
							</div>
						</div>
					</div>
					<div class="wrap">
						<div class="item">
							<div class="tit">网站建设</div>
							<div class="min-tit">BRAND DESIGN</div>
							<div class="line"></div>
							<div class="ll">
								<p>官网建设</p>
								<p>H5 制作</p>
								<p>云会议网站</p>
								<p>商城建设</p>
								<p>门户平台网站</p>
							</div>
						</div>
					</div>
					<div class="wrap">
						<div class="item">
							<div class="tit">小程序开发</div>
							<div class="min-tit">BRAND DESIGN</div>
							<div class="line"></div>
							<div class="ll">
								<p>微信小程序</p>
								<p>QQ 小程序</p>
								<p>支付宝小程序</p>
								<p>百度小程序</p>
								<p>抖音小程序</p>
							</div>
						</div>
					</div>
					<div class="wrap">
						<div class="item">
							<div class="tit">APP开发</div>
							<div class="min-tit">BRAND DESIGN</div>
							<div class="line"></div>
							<div class="ll">
								<p>安卓 APP</p>
								<p>IOS APP</p>
								<p>鸿蒙 APP</p>
							</div>
						</div>
					</div>
					<div class="wrap">
						<div class="item">
							<div class="tit">定制系统开发</div>
							<div class="min-tit">BRAND DESIGN</div>
							<div class="line"></div>
							<div class="ll">
								<p>OA 系统</p>
								<p>进销存系统</p>
								<p>会员系统</p>
								<p>运营系统</p>
								<p>客服系统</p>
							</div>
						</div>
					</div>
					<div class="wrap">
						<div class="item">
							<div class="tit">新媒体运营</div>
							<div class="min-tit">BRAND DESIGN</div>
							<div class="line"></div>
							<div class="ll">
								<p>微信运营</p>
								<p>抖音运营</p>
								<p>微博运营</p>
								<p>头条运营</p>
								<p>社群运营</p>
							</div>
						</div>
					</div>
					<div class="wrap">
						<div class="item">
							<div class="tit">企业网络排名</div>
							<div class="min-tit">BRAND DESIGN</div>
							<div class="line"></div>
							<div class="ll">
								<p>SEO 排名</p>
								<p>SEM 竞价</p>
								<p>SEO 评测</p>
								<p>SEO 优化</p>
							</div>
						</div>
					</div>
					<div class="wrap">
						<div class="item">
							<div class="tit">720°全景</div>
							<div class="min-tit">BRAND DESIGN</div>
							<div class="line"></div>
							<div class="ll">
								<p>全景拍摄</p>
								<p>全景展示</p>
								<p>室内全景</p>
								<p>景区全景</p>
								<p>VR 展厅</p>
							</div>
						</div>
					</div>
					<div class="wrap">
						<div class="item">
							<div class="tit">还有其他需求？</div>
							<div class="min-tit">请立即与我们联系</div>
							<div class="line"></div>
							<a-link to="/contact" class="block btn">联系我们+</a-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<components-partner :partner_list="partner"/>
		<div class="empty"></div>
		<components-contact :active="true"/>
	</main>
</template>

<script>
import ComponentsPartner from "@/components/views/index/partner";
import ComponentsContact from "@/components/views/index/contact";
import {partner} from "@/api/views";
export default {
	components:{ComponentsPartner,ComponentsContact},
	data(){
		return {
			partner:[]
		}
	},
	mounted() {
		this.getPartnerList();
	},
	methods:{
		getPartnerList(){
			partner.all().then((res)=>{
				this.partner = res.list;
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.empty{height:100px;}
.main{text-align: center;
	.title{font-size: 60px;font-weight: bold;line-height: 1; color:$main;padding:170px 0 100px;}
	.min-title{font-size: 40px;color:$main;}
}
.all{
	.center{width: 1200px;margin: 0 auto;
		.title-box{text-align: center;
			.title{display: inline-block; font-size: 34px;position: relative;line-height: 1;}
			.title::after{content: "";display: block;position: absolute; bottom:2px;width: 100%;height:4px;background: $main;z-index: 0;opacity: .6}
		}
		.list{display: flex;flex-wrap: wrap;margin-top: 50px;
			.wrap{width:calc((100% - 100px)/3);margin-right: 50px;margin-bottom: 50px;  border: 1px solid #999;text-align: center;
				.item{padding: 50px;
					.tit{font-size: 28px;color:$main;}
					.min-tit{font-size: 14px;color:#666;}
					.line{width: 50px;height:1px;background: #999;margin: 20px auto 0;}
					.ll{margin-top: 20px;font-size: 16px;color:#666;line-height: 26px;}
					.btn{width: 120px;height:44px;text-align: center;line-height:44px;color:$main;border: 2px solid $main;margin: 40px auto 0;}
				}
			}
			.wrap:nth-child(3n){margin-right: 0;}
		}
	}
}

@media screen and (max-width: 1220px) {
	.empty{height:60px;}
	.main{
		.title{font-size:m(60px);padding:m(170px) 0 m(100px);}
		.min-title{font-size: m(40px);}
	}
	.all{
		.center{width: auto;padding: 0 25px;
			.title-box{
				.title{ font-size: m(34px)}
			}
			.list{margin-top: m(50px);
				.wrap{width:calc((100% - 60px)/3);margin-right: 30px;margin-bottom: 30px;
					.item{padding: m(30px);
						.tit{font-size: 20px;}
						.line{margin:m(20px) auto 0;}
						.ll{font-size: 14px;line-height: 22px;}
						.btn{width:m( 120px);height:m(44px);line-height:m(44px);margin-top: m(40px);font-size: 14px;}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.empty{height:40px;}
	.main{
		.title{font-size:x(60px);padding:x(170px) 0 x(100px);}
		.min-title{font-size: x(40px);}
	}
	.all{margin-bottom: 30px;
		.center{padding: 0 15px;
			.title-box{
				.title{ font-size: m(34px)}
			}
			.list{margin-top: m(50px);justify-content: center;
				.wrap{width:calc((100% - 30px)/2);margin-right: 15px;margin-bottom: 15px;
					.item{padding:15px;
						.tit{font-size: 16px;}
						.min-tit{font-size: 12px;}
						.line{margin:10px auto 0;}
						.ll{font-size: 12px;line-height: 18px;}
						.btn{width:m( 120px);height:m(44px);line-height:m(44px);margin-top: m(40px);font-size: 14px;}
					}
				}
				.wrap:nth-child(3n){margin-right: 15px;}
				.wrap:nth-child(2n){margin-right:0;}
			}
		}
	}
}
</style>

