<template>
	<main>
		<div class="s3">
			<div class="center">
				<div class="title-box">
					<div class="title">我们的客户</div>
				</div>
				<div class="content">
					<div class="swiper" ref="partnerSwiper" id="partner-swiper">
						<div class="swiper-wrapper">
							<div class="swiper-slide" v-for="(item,index) in Math.ceil(partner_list.length/15)" :key="index">
								<template v-for="(v,i) in partner_list" v-if="i >= index*15 && i< (index+1)*15">
									<div class="item" :key="i">
										<div class="wrap">
											<img :src="v.image" :alt="v.title" :title="v.title"/>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
					<div class="page-box">
						<div class="left btn prev" @click="toPrev"><i class="iconfont icon-shangyiyehoutuifanhui"></i><span>PREV</span></div>
						<div class="right btn next" @click="toNext"><span>NEXT</span><i class="iconfont icon-xiayiyeqianjinchakangengduo"></i></div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	props:{
		partner_list:{
			type:Array,
			default:[]
		}
	},
	mounted() {
		this.swiperInit();
	},
	methods: {
		swiperInit(){
			this.partner_swiper = new Swiper('#partner-swiper',{
				autoplay:{
					delay:3000,
					stopOnLastSlide: false,
					disableOnInteraction: true,
				},
				loop:true,
				pagination: {
					el: '#partner-swiper .swiper-pagination',
				},
			})
		},
		toPrev(){
			this.partner_swiper.slidePrev();
		},
		toNext(){
			this.partner_swiper.slideNext();
		}
	},
}
</script>

<style lang="scss" scoped>
.s3{padding: 80px 0 0;
	.center{width: 1200px; margin: 0 auto;
		.title-box{text-align: center;
			.title{font-size: 36px;line-height: 1; display: inline-block;position: relative;}
			.title::after{content: "";display: block;position: absolute; bottom:2px;width: 100%;height:4px;background: $main;z-index: 0;opacity: .6}
		}
		.content{margin-top: 60px;
			.swiper{
				.swiper-wrapper{
					.swiper-slide{display: flex;flex-wrap: wrap;
						.item{width: 20%;height:150px;padding: 10px;overflow: hidden;
							.wrap{background: $border;height:100%; position: relative;transition: all .5s ease;
								img{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%); width:70%;height:50%;object-fit:contain;}
							}
						}
					}
				}
			}
			.page-box{overflow: hidden;height:50px;line-height: 50px;font-size: 16px;
				.btn{
					.iconfont{display:block;float: left; width: 30px;height:50px; line-height: 50px;text-align: center;font-size: 24px;}
					span{display:block;float: left; }
				}
				@media (any-hover: hover){
					.btn:hover{color:$main;cursor: pointer;}
				}
			}
		}
	}
}
@media screen and (max-width: 1220px) {
	.s3{padding:m(80px) 0 0;
		.center{width:auto;padding: 0 25px; margin: 0 auto;
			.title-box{
				.title{font-size:30px;}
			}
			.content{margin-top:m( 40px);
				.swiper{
					.swiper-wrapper{
						.swiper-slide{
							.item{height:m(130px);padding:m( 10px);}
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.s3{padding:x(80px) 0 0;
		.center{width:auto;padding: 0 15px; margin: 0 auto;
			.title-box{
				.title{font-size:24px;}
			}
			.content{margin-top:x( 40px);
				.swiper{
					.swiper-wrapper{
						.swiper-slide{
							.item{width: 33.33%; height:x(130px);padding:x( 10px);}
						}
					}
				}
			}
		}
	}
}
</style>

