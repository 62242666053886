var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_vm._m(0),_c('div',{staticClass:"all"},[_c('div',{staticClass:"center"},[_vm._m(1),_c('div',{staticClass:"list"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("还有其他需求？")]),_c('div',{staticClass:"min-tit"},[_vm._v("请立即与我们联系")]),_c('div',{staticClass:"line"}),_c('a-link',{staticClass:"block btn",attrs:{"to":"/contact"}},[_vm._v("联系我们+")])],1)])])])]),_c('components-partner',{attrs:{"partner_list":_vm.partner}}),_c('div',{staticClass:"empty"}),_c('components-contact',{attrs:{"active":true}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('h1',{staticClass:"title"},[_vm._v("SERVICE ITEMS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title"},[_vm._v("服务内容")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("品牌设计")]),_c('div',{staticClass:"min-tit"},[_vm._v("BRAND DESIGN")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"ll"},[_c('p',[_vm._v("LOGO 设计")]),_c('p',[_vm._v("网站设计")]),_c('p',[_vm._v("Web App 设计")]),_c('p',[_vm._v("交互动画设计")]),_c('p',[_vm._v("3D 建模")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("网站建设")]),_c('div',{staticClass:"min-tit"},[_vm._v("BRAND DESIGN")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"ll"},[_c('p',[_vm._v("官网建设")]),_c('p',[_vm._v("H5 制作")]),_c('p',[_vm._v("云会议网站")]),_c('p',[_vm._v("商城建设")]),_c('p',[_vm._v("门户平台网站")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("小程序开发")]),_c('div',{staticClass:"min-tit"},[_vm._v("BRAND DESIGN")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"ll"},[_c('p',[_vm._v("微信小程序")]),_c('p',[_vm._v("QQ 小程序")]),_c('p',[_vm._v("支付宝小程序")]),_c('p',[_vm._v("百度小程序")]),_c('p',[_vm._v("抖音小程序")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("APP开发")]),_c('div',{staticClass:"min-tit"},[_vm._v("BRAND DESIGN")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"ll"},[_c('p',[_vm._v("安卓 APP")]),_c('p',[_vm._v("IOS APP")]),_c('p',[_vm._v("鸿蒙 APP")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("定制系统开发")]),_c('div',{staticClass:"min-tit"},[_vm._v("BRAND DESIGN")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"ll"},[_c('p',[_vm._v("OA 系统")]),_c('p',[_vm._v("进销存系统")]),_c('p',[_vm._v("会员系统")]),_c('p',[_vm._v("运营系统")]),_c('p',[_vm._v("客服系统")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("新媒体运营")]),_c('div',{staticClass:"min-tit"},[_vm._v("BRAND DESIGN")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"ll"},[_c('p',[_vm._v("微信运营")]),_c('p',[_vm._v("抖音运营")]),_c('p',[_vm._v("微博运营")]),_c('p',[_vm._v("头条运营")]),_c('p',[_vm._v("社群运营")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("企业网络排名")]),_c('div',{staticClass:"min-tit"},[_vm._v("BRAND DESIGN")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"ll"},[_c('p',[_vm._v("SEO 排名")]),_c('p',[_vm._v("SEM 竞价")]),_c('p',[_vm._v("SEO 评测")]),_c('p',[_vm._v("SEO 优化")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"tit"},[_vm._v("720°全景")]),_c('div',{staticClass:"min-tit"},[_vm._v("BRAND DESIGN")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"ll"},[_c('p',[_vm._v("全景拍摄")]),_c('p',[_vm._v("全景展示")]),_c('p',[_vm._v("室内全景")]),_c('p',[_vm._v("景区全景")]),_c('p',[_vm._v("VR 展厅")])])])])
}]

export { render, staticRenderFns }